.csat-select
  display: flex
  width: 100%
  flex-direction: column
  align-items: center
  margin-bottom: 30px

.csat-select .answer-list
  width: 100%
  margin: 0
  font-weight: 500
  color: white
  display: flex
  flex-wrap: nowrap
  justify-content: space-between
  margin-bottom: 2rem

  @media (min-width: 767px)
    height: 140px

  div.radio-btn
    width: 11.5rem
    justify-self: stretch
    max-height: 140px

    @media (max-width: 768px)
      width: min-content
      margin: 0 0.3rem

    @media (max-width: 500px)
      max-height: 60px

$accent-color: #5b97ee
$dark-color: #444

.csat-select .radio-btn
  position: relative
  display: block
  label
    display: block
    background: lighten($accent-color, 30%)
    color: $dark-color
    padding: 8px 4px
    //border: 2px solid lighten($accent-color, 20%)
    cursor: pointer
    height: 100%
    max-height: inherit

  input[type="radio"]
    display: none
    position: absolute
    width: 100%
    appearance: none
    &:checked + label
      background: lighten($accent-color, 15%)
      animation-name: blink
      color: white
      font-weight: 600
      animation-duration: 1s
      border-color: $accent-color
      &:after
        background: $accent-color

      &:before
        width: 20px
        height: 20px

.csat-select .csat-emoji
  margin: 1rem
  font-size: 3rem

  @media (max-width: 768px)
    font-size: 2rem

  @media (max-width: 500px)
    font-size: 2rem
    margin: 0.5rem

.hide-on-mobile
  @media (max-width: 500px)
    display: none

@keyframes blink
  0%
    background-color: lighten($accent-color, 15%)

  10%
    background-color: lighten($accent-color, 15%)

  11%
    background-color: lighten($accent-color, 20%)

  29%
    background-color: lighten($accent-color, 20%)

  30%
    background-color: lighten($accent-color, 15%)

  50%
    background-color: lighten($accent-color, 20%)

  45%
    background-color: lighten($accent-color, 15%)

  50%
    background-color: lighten($accent-color, 20%)

  100%
    background-color: lighten($accent-color, 15%)
