$accent-color: #5b97ee
$dark-color: #444

.multiple-select
  display: flex
  width: 100%
  flex-direction: column
  align-items: center

.multiple-select .answer-list
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: space-between

  @media (max-width: 768px)
    justify-content: center
    gap: 20px


.multiple-select .inputGroup
  background-color: #fff
  display: block
  // margin: 1rem 0
  position: relative
  border: 0.5px solid #D1D7DC
  width: 100%
  max-width: 350px
  margin-bottom: 10px

  label
    margin: 0
    padding: 18px 30px
    display: block
    text-align: left
    cursor: pointer
    position: relative
    z-index: 2
    font-weight: 500
    overflow: hidden
    background: lighten($accent-color, 30%)
    color: $dark-color
    border: 2px solid $accent-color
    transition: all 0.25s ease-in-out


    &:before
      width: 100%
      height: 100%
      border-radius: 50%
      content: ''
      position: absolute
      left: 50%
      background: lighten($accent-color, 15%)
      border: 2px solid lighten($accent-color, 10%)
      top: 50%
      transform: translate(-50%, -50%) scale3d(1, 1, 1)
      opacity: 0
      z-index: -1
      transition: all 0.25s ease-in-out

    &:after
      width: 32px
      height: 32px
      content: ''
      border: 1px solid $accent-color
      background-color: white
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ")
      background-repeat: no-repeat
      background-position: 2px 3px
      border-radius: 3px
      z-index: 2
      position: absolute
      right: 30px
      top: 50%
      transform: translateY(-50%)
      cursor: pointer
      transition: all 0.25s ease-in-out



  input:checked ~ label
    color: #fff
    font-weight: 600

    &:before
      transform: translate(-50%, -50%) scale3d(56, 56, 1)
      opacity: 1


    &:after
      background-color: $accent-color
      border-color: $accent-color



  input
    width: 32px
    height: 32px
    order: 1
    z-index: 2
    position: absolute
    right: 30px
    top: 50%
    transform: translateY(-50%)
    cursor: pointer
    visibility: hidden


.grayed-out
  opacity: 0.5
  pointer-events: none

.remove-border
  border: 0 !important