// Only import the few bootstrap stylesheets needed to minize styling conflicts and reduce bundle size
// Mandatory Bootstrap Stylesheets
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';
@import '~bootstrap/scss/_utilities.scss';

// Optional Bootsrap stylesheets
@import '~bootstrap/scss/_root.scss';
@import '~bootstrap/scss/_containers.scss';
@import '~bootstrap/scss/_grid.scss';
@import '~bootstrap/scss/_forms.scss';
@import '~bootstrap/scss/_buttons.scss';
@import '~bootstrap/scss/_modal.scss';

@import '~bootstrap/scss/utilities/api';

.landing-page-wrapper {
  display: grid;
  align-items: center;
  text-align: start;

  &.container {
    width: auto;
  }

  label {
    display: inline-block;
  }

  .form-control {
    max-width: 95%;
    width: 95%;
  }

  button.kq-red {
    background-color: rgb(0 35 102);
    border-color: transparent;
  }

  button.btn.btn-primary {
    padding-left: 30px;
    padding-right: 30px;
  }

  .fw-300 {
    font-weight: 300;
  }

  .customer-logo {
    max-height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    .customer-account-avatar {
      max-width: 100%;
      max-height: inherit;
    }
  }

  @media screen and (max-width: 500px) {
    .customer-logo {
      max-height: 48px;
    }
  }

  .powered-by-ajua {
    padding: 0.5rem 0;
    color: #202d62;
    position: absolute;
    bottom: 0;
    right: 50%;
  }

  .small {
    font-size: small;
  }
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
