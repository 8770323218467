.negative-nps,
.neutral-nps,
.positive-nps
  display: inline-block
  width: 40px
  height: 40px
  border: solid 1px #ccc
  transition: all 0.3s
  background-color: white
  // border-color: transparent
  border-radius: 50%
  color: black

  &:hover
    cursor: pointer

  .nps-form
    margin: 0 1rem
    display: flex
    align-items: center
    justify-content: space-between
    flex-direction: column
    // border: solid 1px #d9d9d9
    // border-radius: 8px
    padding: 10px

    ul.scale-container
      display: flex
      align-items: center
      justify-content: center
      // border: solid 1px #d9d9d9
      padding: 0
      width: fit-content
      margin: 0 0 10px

      label
        display: flex !important
        align-items: center
        justify-content: center
        color: #fff
        margin: 4px
        // font-weight: bold
        // border-color: rgba(255, 255, 255, 0.4)

        &:hover
          // filter: brightness(1.2)
          // box-shadow: inset 0 -.188em lighten(#ccc, 2%)
          // border-color: rgba(75, 92, 220, 0.3)
          // background-color: rgba(75, 92, 220, 0.3)
          // color: #212121

        &:active
          box-shadow: inset 0 0 1.094em #472566
          inset: 0 .063em #390668
          inset: 0 -0.188em #682CA0 0 .063em rgba(255,255,255,.4)

        &.active
          background-color: #ccc

    .scale-indicator
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      font-size: 10pt
      padding: 0px 5px

.nps-score-question
  font-weight: 'normal'
  font-size: '14pt'

// ========================

.scale-container
  display: flex

.scale-container span
  // border: 1px solid black
  // padding: 0.5em 2em
  cursor: pointer
  font-size: 1rem
  font-weight: 500
  transition: all 0.25s ease-in-out

  &:hover
    // background: #2196f3
    color: white

.scale-container .active span
  // border: 1px solid white
  // background: #2196f3
  // padding: 0.7em 2.2em
  color: white

.nps-container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 100%

  @media (max-width: 768px)
    width: 100%

.nps-container h4
  width: 100%

.nps-form
  margin: 1.5em
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding: 10px
  width: 100%

  ul.scale-container
    display: flex
    align-items: center
    justify-content: center
    // border: solid 1px #d9d9d9
    padding: 0
    width: fit-content
    margin: 0

.scale-indicator
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  margin: 1em
  font-size: 0.9rem
  font-weight: 500
