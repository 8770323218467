.scale-container
  display: flex

.scale-container span
  // border: 1px solid black
  // padding: 0.5em 2em
  cursor: pointer
  font-size: 1rem
  font-weight: 500
  transition: all 0.25s ease-in-out
  user-select: none

  &:hover
    background: #2196f3
    color: white

.scale-container .active span
  color: white

.range-container
  display: grid
  grid-template-columns: 30% 70%
  grid-column-gap: 1em
  grid-row-gap: 1em
  align-items: center
  width: 100%
  padding: 0.25rem
  margin: 0

  @media (max-width: 768px)
    grid-template-columns: 1fr
    grid-row-gap: 0.5rem
    margin-bottom: 30px

.range-container h4
  width: 100%
  text-align: start
  max-width: 350px

.range-form
  display: flex
  align-items: center
  justify-content: start
  width: 95%
  justify-self: end
  overflow-x: auto
  margin-bottom: 30px

  ul.scale-container
    display: flex
    align-items: center
    justify-content: space-between
    padding: 0
    width: 100%
    margin: 0

@media (max-width: 768px) 
  ul.scale-container 
    width: 100%
    justify-content: flex-start !important
    gap: 10px
    flex-wrap: wrap

.scale-indicator
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
  margin: 1em
  font-size: 0.9rem
  font-weight: 500

.negative-range,
.neutral-range,
.positive-range
  display: 'inline-block'
  width: 40px
  height: 40px
  border: solid 1px #ccc
  transition: all 0.3s
  background-color: white
  // border-color: transparent
  border-radius: 50%
  color: black
  &:hover
    cursor: pointer
    background-color: #2196f3
    color: white
  &:active
    background-color: #2196f3

.negative-range.active,
.neutral-range.active,
.positive-range.active
  background-color: #2196f3
  color: white

.content-container .range-form ul.scale-container label
  display: flex !important
  align-items: center
  justify-content: center
  color: black
  margin: 4px
  font-weight: bold

  @media (max-width: 768px)
    margin: 0

  &:hover
    cursor: pointer
    background-color: #2196f3
    color: white
