.content-container
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  // min-width: 550px
  max-width: 1200px
  width: 100%
  margin: 0 auto

  &:last-of-type
    margin-bottom: 0

  @media (max-width: 768px)

    .scale-container
      justify-content: center
      label
        width: 33px
        height: 33px
        font-size: 16px

  img.logo-footer
    height: 50px
    margin-top: 10px

  a.powered-by-ajua
    font-size: 12px

  textarea
    width: 100%

  .nps-form
    margin: 1rem 0
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    // border: solid 1px #d9d9d9
    // border-radius: 8px
    padding: 10px

    @media (max-width: 768px)
      margin: 0.5rem 0
      padding: 0

    ul.scale-container
      display: flex
      align-items: center
      justify-content: space-between
      // border: solid 1px #d9d9d9
      padding: 0
      margin: 0 0 10px
      width: 100%
      overflow-x: auto

      label
        display: flex !important
        align-items: center
        justify-content: center
        color: black
        margin: 4px
        font-weight: bold

        @media (max-width: 768px)
          margin: 0

        &:hover,
        .active:hover
          filter: brightness(1.2)
          // box-shadow: inset 0 -.188em lighten(#ccc, 2%)
          // color: #212121
          border-color: rgba(95, 183, 229, 0.6)
          background-color: rgba(95, 183, 229, 0.6)
          color: #212121

        &:active
          // box-shadow: inset 0 0 1.094em #472566
          // inset: 0 .063em #390668
          // inset: 0 -0.188em #682CA0 0 .063em rgba(255,255,255,.4)

        &.active
          // background-color: #ccc
          background-color: #5fb7e5
          border-color: rgba(95, 183, 229, 0.6)

    .scale-indicator
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      font-size: 10pt
      padding: 0px 5px

      @media (max-width: 768px)
        display: none

.company-logo
  // margin-top: 60px
  // height: 150px
  // width: 150px
  max-width: 300px
  width: auto
  max-height: 150px

// ==============================

// .web-form-container
//   font-family: 'Quicksand', sans-serif

.web-form-container
  @media ( max-width: 768px )
    padding: 20px

.web-form-container-font
  font-family: 'Quicksand', sans-serif

.kq-font
  font-family: 'Lucida Sans Regular', sans-serif

.web-form-container button
  cursor: pointer
  margin: 0.5em

.web-form-container .web-view
  // background-image: url("./assets/backgrounds/questions-bg.jpg")
  // background-size: cover
  //height: calc(100vh + 100px)
  width: auto
  max-width: 960px
  margin: 0 auto
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 1rem

@media (max-width: 768px)
  .web-form-container .web-view
    padding: 0.2rem
    width: 97%

.web-form-container .web-view .loader-container
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.web-form-container  h1, .web-form-container h2, .web-form-container h3, .web-form-container h4,
.web-form-container h5, .web-form-container h6
  font-weight: 500
  //margin: 0.5em
  text-align: center
  animation: slideIn
  -webkit-animation-duration: 1s
  -webkit-animation-timing-function: ease-in-out
  -webkit-animation-iteration-count: 1

.web-form-container .captcha div
  animation: slideIn
  -webkit-animation-duration: 1s
  -webkit-animation-timing-function: ease-in-out
  -webkit-animation-iteration-count: 1

@keyframes slideIn
  0%
    opacity: 0
    transform: translateY(90%)

  100%
    opacity: 1
    transform: translateY(0%)

.web-form-container .powered-by
  display: flex
  align-items: center
  justify-content: center
  margin-bottom: 10px
// margin-top: 10px

.web-form-container .powered-by img
  width: 30px
  height: auto
  margin-right: 10px

.web-form-container .powered-by a
  text-decoration: none
  margin-bottom: 0
  font-weight: 600
  color: #002366

.web-form-container .form-submission-success
  width: 100%
  text-align: center
  display: flex
  align-items: center
  flex-direction: column
  justify-content: center

.web-form-container .form-submission-success h1
  font-weight: 700
  font-size: 5em

.web-form-container .form-submission-success i
  font-weight: 700
  font-size: 15em
  color: #88d66f

.web-form-container .form-submission-success.mobile h1
  font-weight: 700
  font-size: 3em

.web-form-container .form-submission-success.mobile i
  font-weight: 700
  font-size: 12em
  color: #88d66f

.web-form-container .web-container div, .web-container button
  animation: slideIn
  -webkit-animation-duration: 1s
  -webkit-animation-timing-function: ease-in-out
  -webkit-animation-iteration-count: 1

.web-form-container .content-container
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  max-width: 1200px
  width: 100%

  @media (max-width: 768px)
    .scale-container
      justify-content: space-between

      label
        width: 33px
        height: 33px
        font-size: 16px

  a.powered-by-ajua
    font-size: 12px

  textarea
    width: 100%

.web-form-container .submit-button
  padding: 10px
  color: white
  background-color: #CA2D32
  // display: block
  justify-content: center
  // vertical-align: middle
  margin-bottom: 15px
  border: none
  border-radius: 6px
  font-weight: 600

  &:hover
    filter: brightness(1.1)

.web-form-container .accept-checkbox
  font-weight: lighter
  font-size: 14px
  color: #BCBCBC
  margin-top: 20px
  margin-left: 10px

.web-form-container .header a
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  font-size: 1rem
  font-weight: 400
  text-decoration: none
  color: black

  &:hover
    color: grey

.web-form-container .header
  position: fixed
  left: 0
  top: 0
  margin-right: auto
  margin-left: 1em

.web-form-container .header.header-mobile
  right: 0

.web-form-container .logo-header
  height: 50px
  margin-top: 10px

.web-form-container .footer
  position: fixed
  bottom: 10px
  text-align: center
  width: 90%
  left: 0
  right: 0
  margin: auto
  padding: 0.5em
  border-radius: 3px
  border: 1px solid #d44a3d
  background: lighten(#d44a3d,15%)
  animation: slideIn
  -webkit-animation-duration: 0.5s
  -webkit-animation-timing-function: ease-in-out
  -webkit-animation-iteration-count: 1
  color: white
  font-weight: 500

.web-form-container .fixed-center
  position: fixed
  /* center the element */
  right: 0
  left: 0
  bottom: 0
  top: 0
  margin-right: auto
  margin-left: auto
  /* give it dimensions */
  width: 100%

.web-form-container .mobile-container
  display: flex
  flex-direction: column
  align-items: center
  height: 100%
  width: 100%
  position: fixed
  padding-bottom: 1.5em

.web-form-container .mobile-content
  flex-grow: 1
  overflow-y: auto

.web-form-container .mobile-heading
  position: sticky
  position: -webkit-sticky
  top: 0
  text-align: center
  z-index: 99
  width: 100%
  background: white

.web-form-container .mobile-answer
  display: flex
  align-items: center
  position: sticky
  width: 90%
  background: white
  align-content: center
  justify-content: center
  z-index: 2
  margin: 0.5em
  padding: 0.4em
  border: 1px solid lightgray
  border-radius: 10px
  -webkit-border-radius: 5px
  -moz-border-radius: 5px

.web-form-container .mobile-answer__button
  padding: 10px
  color: #1a73e8
  border: none
  border-radius: 30px
  font-weight: normal
  display: flex
  flex-direction: column
  align-items: center
  background: white

.web-form-container .mobile-answer__button span
  font-size: 12px

.web-form-container .mobile-answer__area
  padding: 10px
  width: 100%
  margin: 0.2em
  border: none

  &:focus
    outline: none

// CHAT BUBBLES
/* container */
.web-form-container .container
  padding: 5% 5%

/* CSS talk bubble */
.web-form-container .talk-bubble
  margin: 10px 40px
  display: inline-block
  position: relative
  width: 250px
  height: auto
  background: #d2e3fc

.web-form-container .talk-bubble.border.right-top
  border: 8px solid #d2e3fc !important

.web-form-container .talk-bubble.border.left-top
  border: 8px solid #f1f3f4 !important

.web-form-container .talk-bubble.round
  border-radius: 30px
  -webkit-border-radius: 30px
  -moz-border-radius: 30px

/* Right triangle placed top left flush. */
.web-form-container .tri-right.border.left-top:before
  content: ' '
  position: absolute
  width: 0
  height: 0
  left: -40px
  right: auto
  top: -8px
  bottom: auto
  border: 32px solid
  border-color: #f1f3f4 transparent transparent transparent

.web-form-container .tri-right.left-top:after
  content: ' '
  position: absolute
  width: 0
  height: 0
  left: -20px
  right: auto
  top: 0px
  bottom: auto
  border: 22px solid
  border-color: #f1f3f4 transparent transparent transparent

/* Right triangle placed top right flush. */
.web-form-container .tri-right.border.right-top:before
  content: ' '
  position: absolute
  width: 0
  height: 0
  left: auto
  right: -40px
  top: -8px
  bottom: auto
  border: 32px solid
  border-color: #d2e3fc transparent transparent transparent

.web-form-container .tri-right.right-top:after
  content: ' '
  position: absolute
  width: 0
  height: 0
  left: auto
  right: -20px
  top: 0px
  bottom: auto
  border: 20px solid
  border-color: #d2e3fc transparent transparent transparent

.web-form-container .right-top
  position: relative
  float: right
  color: #174ea6

.web-form-container .left-top
  position: relative
  float: left
  background-color: #f1f3f4

/* talk bubble contents */
.web-form-container .talktext
  padding: 1em
  text-align: left
  line-height: 1.5em

.web-form-container .talktext p
  /* remove webkit p margins */
  -webkit-margin-before: 0
  -webkit-margin-after: 0
  font-weight: 500
  color: black

.logo-section
  display: flex
  justify-content: center

#webFormProgress
  width: 100%
  background-color: #ddd
  margin: 0.5em 0
  margin-bottom: 20px

#webFormBar
  width: 1%
  height: 30px
  background-color: #CA2D32
  text-align: end
  padding-right: 1em
  line-height: 30px
  color: white
  font-weight: 700

#webFormBar
  transition: width 1s ease-in-out

.required-star
  color: red
  font-size: 1.5em

.question-text
  white-space: pre-wrap
  text-align: left

.x-small
  font-size: x-small

.align-self-end
  align-self: flex-end

.align-self-start
  align-self: flex-start
