.open-ended-container
    text-align: center
    width: 100%
    margin-bottom: 30px


.open-ended-container .flat-text-area
    display: block
    width: 100%
    font-family: inherit
    color: black
    background-color: transparent
    font-size: 24px
    overflow-wrap: break-word
    resize: none
    box-shadow: #CA2D32 0 1px
    padding: 0 0 8px
    border-radius: 0
    border: initial
    border-image: initial
    outline: none
    overflow: hidden
    transition: box-shadow 0.1s ease-out 0s

    @media (max-width: 768px)
        font-size: 1.2rem

.open-ended-container .character-count
    color: gray

.open-ended-container .flat-text-area__error
    border: 2px solid crimson

.open-ended-container .input-comment
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0.1rem