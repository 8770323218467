.single-select
  display: flex
  width: 100%
  flex-direction: column
  margin-bottom: 30px

.single-select .answer-list
  width: 100%
  margin: 0.5em
  font-weight: 500
  color: white
  display: flex
  flex-wrap: wrap
  justify-content: space-between

  @media (max-width: 768px)
    justify-content: center
    gap: 20px

  div.radio-btn
    width: 350px

$accent-color: #5b97ee
$dark-color: #444

.single-select .radio-btn
  position: relative
  display: block
  label
    display: block
    background: lighten($accent-color, 30%)
    color: $dark-color
    padding: 10px 20px
    border: 2px solid lighten($accent-color, 20%)
    margin-bottom: 5px
    cursor: pointer
    &:after,
    &:before
      content: ""
      position: absolute
      right: 11px
      // top: 11px
      width: 20px
      height: 20px
      border-radius: 3px
      background: lighten($accent-color, 15%)

    &:before
      background: transparent
      transition: 0.2s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s, 0.2s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s
      z-index: 2
      overflow: hidden
      background-repeat: no-repeat
      background-size: 13px
      background-position: center
      width: 0
      height: 0
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+)

  input[type="radio"]
    display: none
    position: absolute
    width: 100%
    appearance: none
    &:checked + label
      background: lighten($accent-color, 15%)
      animation-name: blink
      color: white
      font-weight: 600
      animation-duration: 1s
      border-color: $accent-color
      &:after
        background: $accent-color

      &:before
        width: 20px
        height: 20px

@keyframes blink
  0%
    background-color: lighten($accent-color, 15%)

  10%
    background-color: lighten($accent-color, 15%)

  11%
    background-color: lighten($accent-color, 20%)

  29%
    background-color: lighten($accent-color, 20%)

  30%
    background-color: lighten($accent-color, 15%)

  50%
    background-color: lighten($accent-color, 20%)

  45%
    background-color: lighten($accent-color, 15%)

  50%
    background-color: lighten($accent-color, 20%)

  100%
    background-color: lighten($accent-color, 15%)
