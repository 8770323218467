.App {
  text-align: center;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
  margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: 0;
}

p {
  margin: 0 0 1em;
  line-height: 1.4285em;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}
